<template>
	<Layout>
		<PageHeader
			:title="title"
			:items="items"
		/>
		<button @click.prevent="logout">
			Logout
		</button>
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Blank page component
 */
export default {
	page: {
		title: "Admin Dashboard",
		meta: [{ name: "AdminDashboard", content: appConfig.description }]
	},
	components: { Layout, PageHeader },
	data() {
		return {
			title: "AdminDashboard",
			items: [
				{
					text: "Turbo Time Tracker",
					href: "/"
				},
				{
					text: "Extra Pages",
					href: "/"
				},
				{
					text: "Blank",
					active: true
				}
			]
		};
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	},
	methods: {
		logout() {
			this.$store.dispatch('auth/logout')
		}
	}
};
</script>